// components/AgencyPixelTracker.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AGENCY_PIXELS = {
  digitalis: 'https://meylotracking.pro/tracking/cpx.php?direct=',
};

export const AgencyPixelTracker = () => {
  const location = useLocation();

  useEffect(() => {
    // Only fire on results page
    if (!location.pathname.includes('/formulaire/results')) {
      return;
    }

    const utmAgency = localStorage.getItem('utm_agency');
    if (!utmAgency || !AGENCY_PIXELS[utmAgency]) {
      return;
    }

    // Generate unique transaction ID using timestamp and random string
    const transactionId = `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
    
    // Create and append pixel
    const pixel = document.createElement('img');
    pixel.src = `${AGENCY_PIXELS[utmAgency]}${transactionId}`;
    pixel.style.display = 'none';
    document.body.appendChild(pixel);

    // Store transaction ID to prevent duplicate firing
    localStorage.setItem('last_transaction_id', transactionId);
    
  }, [location]);

  return null;
};