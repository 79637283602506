// components/Layout/FormLayout.jsx
import React from "react";
import { Outlet } from "react-router-dom";
import HeaderFormVariant from "../Header/HeaderFormVariant";
import FooterStyle4 from "../Footer/FooterStyle4";
import { AgencyPixelTracker } from "../AgencyPixelTracker";

export default function FormLayout() {
  return (
    <>
      <HeaderFormVariant logoSrc="/images/logo.svg" />
      <AgencyPixelTracker />
      <Outlet />
      <FooterStyle4 />
    </>
  );
}